import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import ContactForm from './contact-form';
import './forms.scss';

export const FormBlock = ({ attrs }) => {
  const { gravityForms } = useStaticQuery(
    graphql`
      query formQuery {
        gravityForms: allGfForm {
          nodes {
            formId
            title
            description
            formFields {
              id
              type
              label
              adminLabel
              isRequired
              size
              errorMessage
              nameFormat
              inputs {
                id
                label
                name
                choices {
                  text
                  value
                  isSelected
                  price
                }
                isHidden
                inputType
              }
              formId
              description
              placeholder
              cssClass
              inputName
              visibility
              defaultValue
              choices
              conditionalLogic
            }
            button {
              type
              text
              imageUrl
            }
            confirmations {
              isDefault
              message
            }
          }
        }
      }
    `
  );

  const [gravityForm] = gravityForms.nodes?.filter(
    form => +form.formId === +attrs.formId
  );

  return gravityForm ? <ContactForm gravityForm={gravityForm} /> : null;
};
