import React from 'react';
import Img from 'gatsby-image';
import Blocks from '~/components/blocks';
import { Image } from '~/components/image';
import cc from 'classcat';
import './cover.scss';

export const CoverBlock = ({ attrs, innerBlocks }) => {
  return (
    <section className={cc(['cover', attrs.className])}>
      <div
        className={cc([
          'cover-background',
          attrs.overlayColor && `has-${attrs.overlayColor}-overlay`,
        ])}
      >
        <Image id={attrs.id} className="background-image" />
      </div>
      <div className="container">{<Blocks blocks={innerBlocks} />}</div>
    </section>
  );
};

export const Cover = ({ data, children }) => {
  return (
    <section className={cc(['cover', 'project-hero'])}>
      <div className={cc(['cover-background', 'has-dark-overlay'])}>
        {data.bgImage && (
          <Img
            fluid={data.bgImage?.localFile.childImageSharp.fluid}
            className="background-image"
          />
        )}
      </div>
      <div className="container">{children}</div>
    </section>
  );
};
