import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import './image.scss';

export const Image = ({ id, className, style, alt }) => {
  // get all images
  const data = useStaticQuery(
    graphql`
      query {
        wp: allWordpressWpMedia {
          images: nodes {
            id
            mediaItemId: wordpress_id
            source_url
            alt: alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 640, quality: 30) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  );

  const images = data?.wp?.images;

  // find image by id, both ids converted to numbers
  const image = images.find(img => +img.mediaItemId === +id);

  return image && image.localFile ? (
    <Img
      fluid={image.localFile.childImageSharp.fluid}
      className={className}
      style={style}
      alt={alt || image.alt}
    />
  ) : null;
};

export const ImageBlock = ({ attrs }) => {
  return (
    <figure className="image">
      <Image id={attrs.id} />
      {attrs.caption && (
        <figcaption className="caption">{attrs.caption}</figcaption>
      )}
    </figure>
  );
};
