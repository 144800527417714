import React from 'react';
import Blocks from '~/components/blocks';
import cc from 'classcat';
import './columns.scss';

export const ColumnsBlock = ({ attrs, innerBlocks }) => {
  return (
    <div
      className={cc([
        'columns',
        attrs.className,
        `has-${innerBlocks.length}-columns`,
      ])}
    >
      {innerBlocks.map((col, key) => (
        <div
          key={key}
          className="column"
          style={{ ...(col.attrs.width && { '--w': `${col.attrs.width}%` }) }}
        >
          {<Blocks blocks={col.innerBlocks} />}
        </div>
      ))}
    </div>
  );
};
