import React from 'react';
import Blocks from '~/components/blocks';
import cc from 'classcat';
import './section.scss';

export const SectionBlock = ({ attrs, innerBlocks }) => {
  return (
    <section
      className={cc([
        'section',
        attrs.className,
        attrs.backgroundColor === 'white' && 'light',
        attrs.backgroundColor &&
          `has-${attrs.backgroundColor}-background-color`,
      ])}
    >
      <div className="container">{<Blocks blocks={innerBlocks} />}</div>
    </section>
  );
};
