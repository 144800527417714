import React from 'react';
import { Link } from 'gatsby';
import parse, { domToReact } from 'html-react-parser';
import Swiper from 'react-id-swiper';
import { Image } from '~/components/image';
import 'swiper/css/swiper.css';
import './swiper.scss';

const getLink = url =>
  url.replace(
    `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_HOST}`,
    ''
  );

export const SwiperBlock = ({ attrs }) => {
  const { items } = attrs;

  // swiper props
  const params = {
    slidesPerView: 'auto',
    spaceBetween: 24,
    a11y: false,
    slideToClickedSlide: true,
    pagination: {
      el: '.swiper-dots',
      type: 'bullets',
      clickable: true,
    },
    breakpoints: {
      720: {
        slidesPerView: 1,
        spaceBetween: 40,
      },
    },
    renderPagination: () => {
      return (
        <div className="swiper-nav">
          <span className="label">{attrs.caption}</span>
          <span className="swiper-dots"></span>
        </div>
      );
    },
  };

  // hack link in for now until featured component is ready
  const slides = items?.map(item => {
    let link;
    const replace = node => {
      if (
        node.name === 'a' &&
        node.attribs.href &&
        getLink(node.attribs.href).charAt(0) === '/'
      ) {
        link = getLink(node.attribs.href);
        return <span>{domToReact(node.children)}</span>;
      }
    };

    const caption = parse(item.caption, { replace });
    return { ...item, link, caption };
  });

  return (
    <Swiper {...params}>
      {slides?.map(item => {
        return (
          <Link to={item.link} key={item.id} className="slide-link">
            <figure className="slide-image">
              <Image id={item.id} />
              {item.caption && (
                <figcaption className="slide-caption meta">
                  {item.caption}
                </figcaption>
              )}
            </figure>
          </Link>
        );
      })}
    </Swiper>
  );
};
