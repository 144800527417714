import React, { createRef, useState, Fragment } from 'react';

const ContactForm = props => {
  const { gravityForm } = props;

  const formRef = createRef();
  const [formState, setFormState] = useState('init');
  const [formResponse, setFormResponse] = useState('init');

  const handleSubmit = async e => {
    e.preventDefault();

    setFormState('sending');

    const input_values = {};
    const formElements = Array.from(formRef.current.elements);
    formElements
      .filter(el => el.tagName !== 'BUTTON')
      .map(el => (input_values[el.id] = el.value));
    const data = { input_values };

    const url = `${process.env.GATSBY_API_PROTOCOL}://${process.env.GATSBY_API_HOST}/gravityformsapi/forms/${gravityForm.formId}/submissions`;

    const request = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(data => {
        if (data.status > 202) {
          setFormState(`error: ${data.status}`);
        } else {
          setFormState('sent');
        }
        return data.json();
      })
      .catch(error => {
        setFormState(`error`);
        return error;
      });

    setFormResponse(request.response);
  };

  switch (formState) {
    case 'init':
    case 'sending':
      return (
        <form
          ref={formRef}
          id={gravityForm.id}
          name={gravityForm.title}
          method="post"
          className="contact-form"
          onSubmit={handleSubmit}
        >
          {gravityForm.formFields &&
            gravityForm.formFields.map(field => (
              <Fragment key={field.id}>
                {field.inputs && field.inputs.length > 1 ? (
                  <div data-name={field.label} className="du__form-row row">
                    <span className="label">{field.label}</span>
                    {field.inputs
                      .filter(i => i.isHidden !== true)
                      .map(input => (
                        <Fragment key={input.id}>
                          {!input.isHidden && (
                            <label className="label">
                              <input
                                id={`input_${input.id}`.replace('.', '_')}
                                name={input.label}
                                type={input.inputType}
                                className={`du__input`}
                                placeholder={input.label}
                                hidden={input.isHidden}
                              />
                            </label>
                          )}
                        </Fragment>
                      ))}
                  </div>
                ) : (
                  <label className="du__form-row row">
                    <span className="label">{field.label}</span>
                    <div className="span-2">
                      {field.type !== 'textarea' ? (
                        <input
                          id={`input_${field.id}`.replace('.', '_')}
                          name={field.label}
                          type={field.type}
                          className={`du__input`}
                          placeholder={field.placeholder}
                        />
                      ) : (
                        <textarea
                          id={`input_${field.id}`.replace('.', '_')}
                          name={field.label}
                          className={`du__textarea`}
                          placeholder={field.placeholder}
                        />
                      )}
                    </div>
                  </label>
                )}
              </Fragment>
            ))}
          <button className="button" type="submit" value="Submit">
            {formState === 'sending' ? 'Sending' : gravityForm.button.text}
          </button>
        </form>
      );
    case 'sent':
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: formResponse?.confirmation_message,
          }}
        ></div>
      );
    default:
      return (
        <div style={{ minHeight: 390 }}>
          <h2>{formState}</h2>
          <p>something went wrong</p>
        </div>
      );
  }
};

export default ContactForm;
