import React from 'react';
import { Link } from 'gatsby';
import parse, { domToReact } from 'html-react-parser';
import cc from 'classcat';

import { CoverBlock } from '~/components/cover';
import { SectionBlock } from '~/components/section';
import { ColumnsBlock } from '~/components/columns';
import { ImageBlock } from '~/components/image';
import { SwiperBlock } from '~/components/swiper';
import { FormBlock } from '~/components/form';

/**
 *  Block:
 *  returns component or parsed html
 */
const Block = props => {
  const { blockName, innerHTML, attrs } = props;

  // Return component if block name matches
  const Component = {
    'core/cover': CoverBlock,
    'core/group': SectionBlock,
    'core/columns': ColumnsBlock,
    'core/image': ImageBlock,
    'core/gallery': attrs.isSwiper && SwiperBlock,
    'gravityforms/form': FormBlock,
  }[blockName];

  if (Component) return <Component {...props} />;

  // Otherwise parse innerHTML with new class names
  const replace = node => {
    const classes = node.attribs?.class;
    if (classes)
      node.attribs.class = cc(
        classes.split(' ').map(c => c.replace('wp-block-', ''))
      );
    if (node.name === 'img' || node.name === 'picture')
      node.attribs.loading = 'lazy';
    if (node.name === 'a' && node.attribs.href?.charAt(0) === '/') {
      return (
        <Link to={node.attribs.href} className={node.attribs.class}>
          {domToReact(node.children)}
        </Link>
      );
    }
  };
  const El = parse(innerHTML.trim(), { replace });
  const Tag = El.type;
  const content = props.children || El.props.children;

  // And return rebuilt inner html as react component
  return <Tag {...El.props}>{content}</Tag>;
};

/**
 *  Blocks:
 *  loops through all blocks & nested blocks
 */
const Blocks = ({ blocks }) => {
  if (!blocks) return null;

  return blocks?.map((block, key) => (
    <Block key={key} {...block}>
      {block.innerBlocks?.length > 0 && <Blocks blocks={block.innerBlocks} />}
    </Block>
  ));
};

export { Block };
export default Blocks;
